<template>
  <div id="app">
    <TemplateDefault></TemplateDefault>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TemplateDefault from '@/layouts/TemplateDefault';
import { bus } from '@/utility';

export default {
  name: 'App',
  components: {
    TemplateDefault,
  },
  computed: {
    ...mapGetters([
      'isLongerOffline',
      'connectionStatus',
      'isConnectionAlive',
    ]),
  },
  methods: {
    ...mapActions([
      'updateOnlineStatus',
      'setConnectionStatus',
      'checkLockedBets',
      'setConfig',
      'setOffer',
      'setStateData',
      'handleGameState',
      'setDefaultHealthChecker',
    ]),
    setListeners() {
      console.log(' SEEET Listeners ');
      bus.$on('getConfigState', () => {
        console.log(' eventBus on$ getConfigState ');
        this.handleConfigState();
      });
    },
    async handleConfigState() {
      try {
        console.log(' ==>> handleConfigState try');
        this.setDefaultHealthChecker();
        const config = await this.$config.getConfiguration();
        console.log(' ==>> handleConfigState CONFIG ', config);
        this.setConfig(config);
        this.setOffer(config.state.offer);
        this.setStateData(config.state.gameState);
        this.handleGameState(config.state.gameState);
      } catch (e) {
        console.log(' ==>> handleConfigState catch ', e);
        this.setDefaultHealthChecker();
      }
    },
  },
  watch: {
    async isLongerOffline(newValue) {
      console.log(' isLongerOffline ', newValue);
      if (newValue === true) {
        this.setConnectionStatus(false);
        bus.$emit('disableInputs');
      } else {
        this.setConnectionStatus(true);
        bus.$emit('focusFirstInput');
        this.checkLockedBets();
        /**
         * Update config on reconnect
         */
        this.handleConfigState();
      }
    },
    isConnectionAlive(newValue) {
      console.log(' =========== ////////// ConnectionAlive ', newValue);
      console.log(' connectionStatus ', this.connectionStatus);
      if (newValue === false && this.connectionStatus) {
        this.handleConfigState();
      }
    },
  },
  mounted() {
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
    document.documentElement.setAttribute('theme', 'dark');
    this.setListeners();
  },
  onUnmounted() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },
  beforeDestroy() {
    bus.$off('getConfigState');
  },
};
</script>

<style lang="scss">
*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  user-select: none;
  outline: none;
}

@font-face {
  font-family: "nfont";
  src: url("https://assets.nsoft-cdn.com/public/gravity/icons/nfont.eot");
  src: url("https://assets.nsoft-cdn.com/public/gravity/icons/nfont.eot?#iefix") format("embedded-opentype"),
       url("https://assets.nsoft-cdn.com/public/gravity/icons/nfont.woff") format("woff"),
       url("https://assets.nsoft-cdn.com/public/gravity/icons/nfont.ttf") format("truetype");
  font-weight: 0;
  font-style: normal;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#app {
  width: 100%;
  height: 100%;
  font-family: Helvetica, Arial, sans-serif;
  font-size: $font-base;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
