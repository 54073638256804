// import { CommonStore } from '@nsoft/games-sdk-js/';
import state from './state';
import getters from './getters';
// eslint-disable-next-line
import actions from './actions';
import mutations from './mutations';
import authPlugin from './plugins/auth';
import modules from './modules';

export default {
  state,
  getters,
  actions,
  mutations,
  plugins: [authPlugin],
  modules,
};
