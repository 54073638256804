import { uuid } from 'vue-uuid';
import { find, each } from 'lodash';
// eslint-disable-next-line
import store from '@/store';

/**
* Check is event already on betslip (games-sdk)
*
* @param {number} id
* @returns {object} returns bet or undefined
*/
const checkBetslip = (id) => {
  const tickets = store.getters['betslip/tickets'];
  return find(tickets, (ticket) => ticket.round === id);
};

/**
* Prepare bet for games betslip (from games-sdk)
*
* @returns {object} return new bet
*/
const createBet = () => {
  const event = store.getters.selectedEvent;
  const market = store.getters.selectedMarket;
  const outcome = store.getters.selectedOutcome;
  const eventName = store.getters.selectedEventName;
  const marketName = store.getters.selectedMarketName;
  const { eventIdToday, timestamp } = event || {};
  const { outcomeId, outcomeType, outcomeCode } = outcome || {};
  const outcomeOdd = outcome?.odd;
  const { marketId } = market[0] || {};
  const newBet = {
    key: uuid.v4(),
    eventName,
    round: eventIdToday,
    displayId: eventIdToday,
    betType: marketId,
    market: marketName,
    timestamp,
    // TODO: check time for event
    // time: formatFns('DD.MM.YYYY. HH:mm', data.event.eventStartTimeUTC);
    // timeTimestamp: data.event.eventStartTimeUTC;
    // outcomeType: `Pick ${outcomeType}`,
    outcomeCode,
    outcome: `${store.getters.translation('pick')} ${store.getters.translation(outcomeType?.toLowerCase() || '')}`,
    odd: outcomeOdd,
    id: outcomeId,
    locked: false,
  };
  return newBet;
};

/**
 * Prepare bets for ticket payin action
 *
 * @param {Array} bets
 * @returns {Array} Returns new bets with
 * game name, idBet, outcomeOdd, outcomeType, betSpecialValue.
 */
const prepareBets = (bets) => {
  const newBets = [];
  each(bets, (bet) => {
    newBets.push({
      eventId: bet.round,
      marketId: bet.betType,
      outcomeId: bet.id,
      outcomeOdd: bet.odd,
      outcomeCode: bet.outcomeCode,
    });
  });
  return newBets;
};

/**
 * Create ticket data for payin action
 *
 * @returns {Object} return new ticket data object that contains
 * ticket (array of bets, payin amount) and
 * metada (requestUuid, product, deliveryPlatform, cpvUuid...)
 */
const createTicket = () => {
  const requestUuid = uuid.v4();
  const { config } = store.getters;
  const tickets = store.getters['betslip/tickets'];
  const payment = store.getters['betslip/payment']; // for combo tikcet is 'payment' from games sdk
  const bets = prepareBets(tickets);
  const ticketData = {};
  const metadata = {
    requestUuid,
    deliveryPlatform: 'Retail', // Shop
    product: config.productFqn, // Roulette
    cpvUuid: config.productId, // Roulette productId
    betshopUuid: '8ea97b7b-2b2c-43b3-b2a4-09914fcc93fa',
    cashRegisterUuid: '676abc08-5d80-439d-b4a3-4bb394e9bb76',
    deviceUuid: '114d36a5-d72c-4f07-840b-b952d2ab7753',
    operatorUuid: 'db379d14-bdd3-435f-bca5-ef8abee2a9d9',
    // tenantUuid: config.tenantId,
  };
  const ticket = {
    bets,
    payin: +payment,
    product: config.productId,
    // ticketComment: '', //  ??
    // ticketType: 2, // ticket type for combo
    // ticketOddsOptions: 2, // default
  };
  if (store.getters.directPayin) {
    ticketData.ticket = ticket;
    ticketData.metadata = metadata;
  }
  return store.getters.directPayin ? ticketData : ticket;
};
const validateBetslip = () => {
  let isValid = true;
  let message = null;
  let acceptPayin = false;

  const isTicketValid = store.getters['betslip/isTicketValid'];
  const betslipNotification = store.getters['notifications/notification'];
  if (store.getters.bettingDisabled || !store.getters.connectionStatus) {
    message = `${store.getters.translation('bettingDisabled')}`;
    isValid = false;
  } else if (!isTicketValid) {
    message = `${store.getters.translation('deleteLockedBets')}`;
    acceptPayin = true;
    isValid = false;
  } else if (betslipNotification.value) {
    message = betslipNotification.value;
    isValid = false;
  }
  return {
    isValid,
    message,
    acceptPayin,
  };
};

export default {
  checkBetslip,
  createBet,
  createTicket,
  validateBetslip,
};
