import {
  each,
  find,
  filter,
  merge,
  omit,
  isEmpty,
  map,
} from 'lodash';

// Update an object's property outcomeType '2X' to 'X2'
const updateOutcomeType = (data) => {
  const newData = map(data, (obj) => {
    if (obj.outcomeType === '2X') {
      return { ...obj, outcomeType: 'X2' };
    }
    return obj;
  });
  return newData;
};

const parseEventMarketOutcomes = (eventMarket, odd) => {
  let outcomes = {};
  const eventMarketOutcome = filter(eventMarket.outcomes, ['outcomeId', odd.outcomeId]);
  // Change outcomeType '2X' to 'X2'
  const updateMarketOutcome = updateOutcomeType(eventMarketOutcome);
  outcomes = {
    outcomeId: eventMarketOutcome[0].outcomeId,
    outcomeType: updateMarketOutcome[0].outcomeType,
    outcomeCode: eventMarketOutcome[0].outcomeCode,
    odd: odd.value,
  };
  return outcomes;
};

const parseEventMarket = (offer, odds) => {
  const eventOffer = [];
  each(odds, (odd) => {
    const eventMarket = find(offer, ['marketId', odd.marketId]);
    eventOffer.push({
      marketId: eventMarket.marketId,
      marketType: eventMarket.marketType,
      limitValue: eventMarket.limitValue,
      outcomes: parseEventMarketOutcomes(eventMarket, odd),
    });
  });
  return eventOffer;
};

const parseEvents = (offer, data) => {
  const gameEvents = [];
  each(data, (event) => {
    gameEvents.push(merge(omit(event, ['odds']), {
      odds: parseEventMarket(offer, event.odds),
    }));
  });
  return gameEvents;
};

const parseOutcomePerCode = (offer, value) => {
  const marketOutcome = [];
  each(offer, (market) => {
    const eventMarket = filter(market.outcomes, ['outcomeCode', value]);
    // Change outcomeType '2X' to 'X2'
    const updateEventMarket = updateOutcomeType(eventMarket);
    if (!isEmpty(eventMarket)) {
      marketOutcome.push({
        marketType: market.marketType,
        limitValue: market.limitValue,
        outcomeType: updateEventMarket[0].outcomeType,
      });
    }
  });
  return marketOutcome[0];
};

export default {
  parseEvents,
  parseOutcomePerCode,
};
